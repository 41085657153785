* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --color-001: rgb(7, 155, 241);
    --color-002: rgb(222, 76, 27);
    --color-003:  rgb(127, 238, 179);
    --color-004:  #BE3455;
    --item-shadow: rgba(50, 50, 93, 0.25) 1px 2px 15px 1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

a {
    text-decoration: none;
    display: inline-block;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}


.main-content-splitter {
    color: rgb(145, 143, 143);
}

.green {
    color: var(--color-003);
}


.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: calc(370 / 420 * 100%);
}

.video-wrapper video,
.video-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--item-shadow);
}

/* NAVBAR STYLING */

.header-class {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: none;
    background-color: white;
}

.navbar {
    padding-top: 1.25rem;
    margin: 0 auto;
    background-color: white;
    transition: padding 0.5s ease;
}

.shrink .navbar {
    padding-top: 0;
}

.shrink {
    box-shadow: -20px 25px 50px 10px rgba(158, 158, 158, 0.3);
    transition: all 1s ease;
}

@media (max-width: 990px) {
    .navbar {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

.navbar-container {
    max-width: 1400px;
}

@media (max-width: 1400px) {
    .navbar {
        max-width: 1200px;
    }
}

@media (max-width: 1200px) {
    .navbar {
        max-width: 1000px;
    }
}

@media (max-width: 990px) {
    .navbar {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

#logo {
    width: 100%;
    height: auto;
}

.navbar-brand {
    width: 75px;
    height: auto;
}

.nav-link {
    list-style: none;
}


.nav-link {
    text-decoration: none;
    color: black !important;
    font-weight: 400 !important;
    transition: color 0.3s ease-in-out !important;
}

.nav-link:hover {
    text-decoration: none;
    color: var(--color-001) !important;
}

.nav-item {
    margin-left: 4px;
}

.navbar-toggler {
    color: white;
    background-color: var(--color-004);
    border-radius: 10%;
    transition: background-color 0.5s ease;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler:hover {
    box-shadow: none;
    background-color:  rgb(255, 105, 140);
}

.navbar-nav {
    margin-left: 0.55rem;
}
.navbar-collapse {
    background-color: white;
    margin-top: 1rem;
    padding-bottom: 1rem;
}

/* MAIN TITLE SECTION */

.title-section {
    padding-top: 100px;
}

.title-container {
    padding: 5rem;
}

.main-title .title-name {
    font-size: 60px;
    padding-top: 30px;
    max-width: 100%;
    font-weight: 500;
}


@media (max-width: 600px) {
    .title-container {
        width: 100%;
        padding: 0;
    }
}


.main-title .title-description {
    font-weight: 300;
    width: 75%;
}

.title-splitter {
    width: 300px; 
    margin: 0 auto;
}

@media (max-width: 1400px) {
    .main-title .title-name {
        font-size: 40px;
    }

    .title-splitter {
        width: 150px; 
    }
}

@media (max-width: 555px) {
    .title-splitter {
        display: none;
    }
}

@media (max-width: 600px) {
    .about-text{
        text-align: center;
    }

    .about-splitter {
        margin: 0 auto;
    }
}


.personal-links {
    list-style: none;
}

.personal-links-container ul {
    padding: 0 !important;
}

.personal-links i {
    font-size: 40px;
    color: black;
    padding: 0 !important;
    transition: color 0.3s ease;
}

.personal-links i#kaggle-icon {
    font-size: 20px;
    color: white;
    background-color: black;
    padding: 10px 14px !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    transition: background-color 0.3s ease;
}

.personal-links i:hover {
    color: var(--color-001);
}

.personal-links i#kaggle-icon:hover {
    background-color: var(--color-001);
}


/* ABOUT SECTION */

.about-me h1 {
    font-size: 30px;
    font-weight: 700;
}

.about-img {
    height: auto;
    width: 100%;
    margin: 0 auto !important;
    
}

.about-splitter {
    width: 200px;
}

.about-main-text {
    font-weight: 300;
}

.about-links {
    list-style: none;
    padding-left: 0px;
    gap: 10px;
}

.about-link-button {
    margin-top: 10px;
    margin-left: 0px;
    padding: 8px 15px;
    border: solid 1px;
    border-radius: 8px;
    color: black;
}

.about-link-button:hover {
    color: var(--color-001);
    transition: color 0.3s ease-in-out;
}


/* PORTFOLIO */

.portfolio-splitter {
    width: 200px;
    margin: 0 auto;
}

.portfolio-item-splitter {
    color: black;
    margin-top: -80px;
}


.project-item img {
    width: 100%;
    height: auto;
}

#fraud-img {
    padding: 15px;
    box-shadow: var(--item-shadow);
}

#ticket-img {
    padding: 50px 0px;
    box-shadow: var(--item-shadow);
}

#emission-img {
    padding: 20px;
    box-shadow: var(--item-shadow);
}

.project-item p {
    font-weight: 300;
}

.project-info-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 300;
}

.project-info-list span {
    font-weight: 500;
}

.project-links {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.project-links i {
    color: black;
    font-size: 30px;
}

.project-links i:hover {
    color: var(--color-001);
    font-size: 30px;
    transition: color 0.3s ease-in-out;
}


/* FOOTER SECTION */

.footer-class {
    /* background-image: url("../public/imgs/space-bg.jpg");
    background-size: cover;
    background-position: top;
    background-attachment: fixed; */
    background-color: rgb(5, 4, 34);
}

.footer-text {
    color: rgba(197, 199, 201, 0.757);
    font-weight: 300;
    text-align: justify;
}


@media (max-width: 990px) {
    .footer-text {
        text-align: left;
    }
}


.footer-class .personal-links i {
    color: white;
    transition: color 0.3s ease;
}

.footer-class .personal-links i#kaggle-icon {
    color: black;
    background-color: white;
    font-weight: 1000;
    transition: background-color 0.3s ease;
}

.footer-class .personal-links i:hover {
    color: var(--color-001);
}

.footer-class .personal-links i#kaggle-icon:hover {
    background-color: var(--color-001);
}

.footer-title {
    color: white;
}

.contact-container p {
    width: 80%;
}

#gitlink {
    color: var(--color-003);
}